<template>
  <div class="newsCenter">
    <PageHeader :navData="navData"></PageHeader>
    <div class="title-url">
      <div class="title">
        <span @click="goHome()">{{ $t('Home') }}</span>
        <span class="line"></span>
        <span>{{ $t('News') }}</span>
      </div>
    </div>
    <div class="center">
      <div :class="['content-center']">
        <div class="card-box">
          <div v-for="(item, index) in showContentData" :key="index">
            <el-card :body-style="{ padding: '0px' }">
              <router-link :to="`/newsCenter/${item.id}`">
                <img :src="item.newsImgs" class="image" />
                <div class="text-box">
                  <span class="title">{{ item.newsTitle }}</span>
                  <span class="date">{{ item.createDate }}</span>
                </div>
              </router-link>
            </el-card>
          </div>
        </div>
      </div>
      <div :class="['content-bottom']">
        <el-pagination
          :background="false"
          layout="prev, pager, next"
          :current-page.sync="currentPage"
          @size-change="getData(currentPage, $event)"
          @current-change="getData()"
          :page-size="8"
          :hide-on-single-page="false"
          :prev-text="$t('previousPage')"
          :next-text="$t('nextPage')"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'newsCenter',
  data() {
    return {
      total: 10,
      currentPage: 1,
      pagesize: 9,
      // 列表数据
      showContentData: [],
      // 导航数据
      navData: {
        navigationDescribe: '',
        navigationImg: '',
        navigationName: ''
      }
    }
  },
  created() {
    this.getNavImg()
    this.getData()
  },
  methods: {
    // 获取导航信息
    getNavImg() {
      this.$axios
        .post('/officialweb/data/getGwNavigation', {
          navigationType: '4'
        })
        .then((res) => {
          this.navData.navigationDescribe = res.data.navigationDescribe
          this.navData.navigationImg = res.data.navigationImg
          this.navData.navigationName = res.data.navigationName
        })
        .catch(() => {})
    },
    // 返回首页
    goHome() {
      this.$router.push({ path: '/' })
    },
    // 列表接口
    getData() {
      let formData = {
        id: '',
        pageNo: this.currentPage,
        pageSize: this.pagesize
      }
      this.$axios
        .post('/officialweb/data/findGwNewsInfoList', formData)
        .then((res) => {
          this.showContentData = res.list.map((item) => {
            item.newsImgs = item.newsImgs
              .replace('[', '')
              .replace(']', '')
              .split(',')
            return item
          })
          this.total = res.count
        })
        .catch(() => {})
    }
  }
}
</script>
<style lang="less" scoped>
.content-bottom {
  .el-pagination {
    margin: 40px 0;
    text-align: right;
  }
  /deep/ .el-pager li:hover {
    background-color: #0079cf;
    border-color: #0079cf;
    color: #fff;
  }
  /deep/ .el-pager li:active {
    border-color: #0079cf;
    color: #0079cf;
  }
  /deep/ .el-pager li {
    border: 1px solid #999;
    margin-right: 6px;
    &:first-child {
      margin-left: 6px;
    }
  }
  /deep/ .el-pagination.is-background .el-pager li {
    background-color: transparent;
    border: 1px solid #999;
  }
  /deep/ .el-pagination button {
    border: 1px solid #999;
  }
  /deep/ .el-pagination button:disabled {
    border: 1px solid #999;
  }
}

.center {
  width: @mainSize;
  margin: 0 auto;
  padding-top: 30px;
  overflow: hidden;
  .content-center {
    width: 100%;
    height: 100%;
    background-color: #eee;
    box-sizing: border-box;
    .card-box {
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 30px;
      padding: 20px;
      box-sizing: border-box;
      .el-card {
        width: 100%;
        height: 100%;
        border-radius: 0;
        position: relative;
        cursor: pointer;
        border: none;
        background-color: #fff;
        &:hover {
          box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
          transform: translate3d(0, -3px, 0);
          a {
            text-decoration: none;
          }
        }
      }
      .el-card__body {
        width: 100%;
        height: 100%;
        overflow: hidden;
        img {
          width: 100%;
          height: 280px;
          object-fit: fill;
        }
      }
    }

    .text-box {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      height: 70px;
      color: #343434;
      text-align: left;
      padding: 5px 10px;
      box-sizing: border-box;
      overflow: hidden;
      span {
        font-weight: bold;
        height: 30px;
        line-height: 30px;
      }
      .title {
        font-size: 14px;
        height: 30px;
        line-height: 30px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        &:hover {
          color: @mainColor;
        }
      }
      .date {
        font-size: 12px;
        color: #a8a8a8;
      }
    }
  }
}
</style>
